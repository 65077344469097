import Gleap from "gleap";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import "react-perfect-scrollbar/dist/css/styles.css";
import "tailwindcss/tailwind.css";
import "../styles/globals.scss";
// let FontFaceObserver = require('fontfaceobserver');

function App({ Component, pageProps }: AppProps) {
  // if (typeof window !== "undefined") {
  //   let fontA = new FontFaceObserver("Archivo Narrow");
  //   fontA.load().then(function () {
  //     //console.log('Archivo Narrow');
  //   });
  // }

  useEffect(() => {
    // Run within useEffect to execute this code on the frontend.
    Gleap.initialize("zljNbf1RnaPhNUxVOOOTXhHBcvytKaMF");
  }, []);

  return (
    <>
      <Head>
        <title>IntuCharge</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Component {...pageProps} />
      <Toaster />
    </>
  );
}

export default App;
